


.footer {
    padding-left: 17.1875rem;
    background-color: $body-bg;
    
    .copyright {
        padding: 0.9375rem;
        p {
            text-align: center;
            margin: 0;
        }

        a{
            color: $primary;
        }
    }
}


